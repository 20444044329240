<template>
  <v-form ref="form" v-model="valid" validate-on="blur" @submit.prevent="save">
    <v-container grid-list-md text-xs-left>
      <v-layout wrap>
        <v-flex xs12>
          <v-label>First Name</v-label>
          <v-text-field
            id="chaperoneFormFirstName"
            v-model="model.firstName"
            solo
            flat
            :rules="[isRequired]"
          />
        </v-flex>
        <v-flex xs12>
          <v-label>Last Name</v-label>
          <v-text-field
            id="chaperoneFormLastName"
            v-model="model.lastName"
            solo
            flat
            :rules="[isRequired]"
          />
        </v-flex>
        <v-flex xs12>
          <v-label>Email</v-label>
          <v-text-field
            id="chaperoneFormEmail"
            v-model="model.email"
            solo
            flat
            :rules="[isRequired, validEmail]"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-label>Phone Number</v-label>
          <v-text-field
            id="chaperoneFormPhone"
            v-model="model.phone"
            solo
            flat
            :rules="[isRequired, validPhone]"
          />
        </v-flex>
        <v-flex xs12>
          <v-btn
            id="chaperoneFormSave"
            type="submit"
            color="blue darken-1"
            block
            dark
          >
            Save
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { PhoneNumberUtil } from 'google-libphonenumber'
const phoneUtil = PhoneNumberUtil.getInstance()

const defaultChaperone = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

export default {
  name: 'ChaperoneForm',
  props: {
    chaperone: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      phoneUtil,
      valid: false,
      model: Object.assign({}, defaultChaperone),
      isRequired: (value) => !!value || 'Required.',
      validPhone: (value) => {
        let validPhone
        try {
          let number = phoneUtil.parse(value, 'US')
          validPhone = phoneUtil.isPossibleNumber(number)
        } catch (e) {
          validPhone = false
        }
        return validPhone || 'Invalid phone number.'
      },
      validEmail: (value) => /.+@.+\..+/.test(value) || 'Invalid email.',
    }
  },
  mounted() {
    this.reset()
    Object.assign(this.model, this.chaperone)
    if (this.chaperone) {
      this.$refs.form.validate()
    }
  },
  methods: {
    reset() {
      this.model = Object.assign({}, defaultChaperone)
      this.$refs.form.reset()
    },
    close() {
      // todo - this is a hack to close the dialog
      this.$store.dispatch('app/closeSidebarDialog')
      this.$emit('close')
      this.reset()
    },
    async save() {
      if (this.valid) {
        if (this.chaperone != null) {
          this.$store.dispatch('chaperones/updateChaperone', this.model)
        } else {
          const newChaperone = await this.$store.dispatch(
            'chaperones/addChaperone',
            this.model
          )

          // if created underneath a customer account, link it
          const customerAccount = this.$store.getters[
            'customerAccounts/customerAccount'
          ](+this.$route.params.id)

          if (customerAccount) {
            this.$store.dispatch('chaperones/linkCustomerAccount', {
              chaperone: newChaperone,
              customerAccount,
            })
          }
        }
        this.close()
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
